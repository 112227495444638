<template>
  <main class="ml-6">
  <trac-loading class="" v-if="loading" />
    <div class="flex-col mt-4 mb-8">
      <trac-back-button> Order Details </trac-back-button>
      <h1 class="font-medium mt-2">Add Payment</h1>
    </div>
    <div class="bg-white rounded-lg big-shadow px-8 py-8">
      <span class="text-xs text-primaryGray">via</span>
      <div class="mt-5 grid grid-cols-5 gap-5">
        <div class="w-full">
          <div
            :class="{
              'bg-primaryBlue text-white': paymentMethod == 'cash',
            }"
            @click="paymentMethod = 'cash'"
            class="bg-white flex justify-center items-center cursor-pointer h-full big-shadow rounded-lg border border-primaryBlue"
          >
            <div class="flex items-center w-full px-6 py-8">
              <img class="h-12 w-12" src="../../assets/svg/pay_method.svg" />
              <span class="font-semibold pl-6"> Cash</span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div
            :class="{
              'bg-primaryBlue text-white': paymentMethod == 'pos',
            }"
            @click="paymentMethod = 'pos'"
            class="bg-white flex justify-center items-center cursor-pointer h-full big-shadow rounded-lg border border-primaryBlue"
          >
            <div class="flex items-center w-full px-6 py-8">
              <img class="h-12 w-12" src="../../assets/svg/trans_type.svg" />
              <span class="font-semibold pl-6"> POS</span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div
            :class="{
              'bg-primaryBlue text-white': paymentMethod == 'own_bank_account',
            }"
            @click="paymentMethod = 'own_bank_account'"
            class="bg-white flex justify-center items-center cursor-pointer h-full big-shadow rounded-lg border border-primaryBlue"
          >
            <div class="flex items-center w-full px-6 py-8">
              <img class="h-12 w-12" src="../../assets/svg/bank.svg" />
              <span class="font-semibold pl-6">
                Bank <br />
                Transfer</span
              >
            </div>
          </div>
        </div>
        <div class="w-full">
          <div
            :class="{
              'bg-primaryBlue text-white': paymentMethod == 'bank_transfer',
            }"
            @click="paymentMethod = 'bank_transfer'"
            class="bg-white flex justify-center items-center cursor-pointer h-full big-shadow rounded-lg border border-primaryBlue"
          >
            <div class="flex items-center w-full px-4">
              <img class="h-12 w-12" src="../../assets/svg/bank.svg" />
              <span class="font-semibold pl-6">
                Bank <br />
                Transfer <br />
                (Traction Pay)
              </span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div
            :class="{
              'bg-primaryBlue text-white': paymentMethod == 'online_payment',
            }"
            @click="paymentMethod = 'online_payment'"
            class="bg-white flex justify-center items-center cursor-pointer h-full big-shadow rounded-lg border border-primaryBlue"
          >
            <div class="flex items-center w-full px-4">
              <img class="h-12 w-12" src="../../assets/svg/pay_method.svg" />
              <span class="font-semibold pl-6"> Online Method </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-12 mt-16">
        <div class="flex-column w-4/12">
          <trac-input v-model="amount" placeholder="Amount"></trac-input>
        </div>
        <div class="flex-column w-4/12">
          <!-- <trac-input placeholder="Record Date"></trac-input> -->
          <div class="relative">
            <date-picker
              placeholder="Input Date"
              ref="datepicker"
              class="text-xs"
              v-model="paymentDate"
              format="YYYY-MM-DD"
              type="date"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="flex flex-row">
          <span class="font-medium text-sm text-primaryGray w-2/12"
            >Total invoice amount:</span
          >
          <span class="font-semibold text-base">{{
            logs.total | formatPrice
          }}</span>
        </div>
        <div class="flex flex-row">
          <span class="font-medium text-sm text-primaryGray w-2/12"
            >Payment made:</span
          >
          <span class="font-semibold text-base">
            {{ TotalPayment | formatPrice }}</span
          >
        </div>
        <div class="flex flex-row">
          <span class="font-medium text-sm text-primaryGray w-2/12"
            >Balance Due:</span
          >
          <span class="font-semibold text-base">
            {{ (+logs.total - TotalPayment) | formatPrice }}</span
          >
        </div>
        <div class="mt-4">
          <trac-button @button-clicked="recordPayment">Confirm</trac-button>
        </div>
      </div>
      <div class="mt-10">
        <h1 class="uppercase font-medium">
          Payment <span class="font-bold">LOG</span>
        </h1>
        <svg
          width="20"
          height="4"
          viewBox="0 0 20 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="-9.46895"
              y1="2.21242"
              x2="-7.84897"
              y2="9.88217"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#06B6CB" />
              <stop offset="1" stop-color="#253B95" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="mt-8 border rounded-lg overflow-auto h-60">
        <table class="w-full table-auto overflow-auto">
          <thead class="bg-blue-100">
            <tr class="h-16">
              <th class="text-left pl-8 text-xs font-semibold uppercase">
                Date
              </th>
              <th class="text-left text-xs font-semibold uppercase">
                Payment Method
              </th>
              <th class="text-left text-xs font-semibold uppercase">Amount</th>
            </tr>
          </thead>
          <tbody v-if="logs.payment_log.length">
            <tr
              :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(log, index) in paymentLog"
              class="h-16"
              :key="index"
            >
              <td class="text-xs font-medium pl-8">
                {{ log.date_of_payment }}
              </td>
              <td class="text-xs font-medium">
                {{ log.payment_method }}
              </td>
              <td class="text-xs font-medium">
                {{ log.amount_received }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="py-5 pl-10 text-xs">No available data</td>
            </tr>
          </tbody>
        </table>
      </div>
      <trac-confirmation
        @close="confirmation = $event"
        :type="type"
        :active="confirmation"
      >
        {{ message }}
      </trac-confirmation>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  name: "AddPayment",
  data() {
    return {
      loading: false,
      logs: null,
      paymentMethod: '',
      amount: '',
      paymentDate: '',
      confirmation: null,
      message: null,
      type: null
    }
  },
  methods: {

    async recordPayment() {
      if (this.paymentMethod === '') {
        this.message = 'Please select a payment method.';
        this.confirmation = true
        this.type = false;
        return;
      }
      if (this.amount.trim() === '') {
        this.message = 'Please enter a valid amount.';
        this.confirmation = true
        this.type = false;
        return;
      }
      if (+this.amount > +this.logs.total - this.TotalPayment) {
        this.message = `Amount entered can't be greater than pending amount.`;
        this.confirmation = true
        this.type = false;
        return;
      }
      if (this.paymentDate === '') {
        this.message = `Please enter a valid payment date.`;
        this.confirmation = true
        this.type = false;
        return;
      }
      const date = new Date(this.paymentDate).toISOString();
      this.loading = true;

      let payload = {
        business_id: GET_USER_BUSINESS_ID(),
        order_id: this.logs.id,
        amount: +this.amount,
        payment_date: date.substring(0, date.length - 1),
        payment_method: this.paymentMethod
      }
      let res = await this.$store.dispatch('RECORD_PAYMENT', payload);
      if (res.status) {
        this.amount = '';
        this.paymentDate = '';
        this.$store.commit("logOrders", res.data)
        this.logs.payment_log = res.data.payment_log
        this.message = `Payment made succesfully`;
        this.confirmation = true
        this.type = true;
      }
      this.loading = false;
    }
  },
  computed: {
    TotalPayment() {
      if (this.logs && this.logs.payment_log !== null) {
        return this.logs.payment_log.reduce((x, y) => x + y.amount_received, 0)
      }
    },
    paymentLog() {
      return this.logs.payment_log.sort((a, b) => a.date_of_payment < b.date_of_payment)
    }
  },
  created() {
    this.logs = this.$store.state.orders.orderLog;
    // (this.logs)
  }
}
</script>

<style>
.mx-input {
  height: 42px !important;
  font-size: 12px !important;
  width: 13.5rem !important;
}
</style>